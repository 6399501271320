// Header.scss
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  width: 100%;
  height: 82px;
  background-color: #fff;
  z-index: 1000;

  .logo {
    display: flex;
    width: 570px;

    h1 {
      color: #585858;
      text-align: center;
      font-family: Amaranth;
      font-size: 30.819px;
      font-style: normal;
      font-weight: 400;
      line-height: 40.345px;
      /* 130.909% */
      letter-spacing: 0.28px;
    }
  }

  .menu {
    display: flex;
    height: 82px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    a {
      text-decoration: none;
      color: var(--Black-T, #44423f);
      text-align: center;
      cursor: pointer;

      /* Secondary (R) */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      padding: 10px 14px;
    }
  }

  .hamburger-menu {
    display: none;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    .logo {
      margin-left: 30px;
    }
    .hamburger-menu {
      margin-right: 30px;
    }

    display: flex;

    .menu {
      display: none;
      flex-direction: column;
      background: var(--White, #fff);
      position: absolute;
      top: 82px; // Adjust the top position as needed
      left: 0;
      width: 100%;
      height: 56px;
      padding: 10px 30px;
      align-items: center;
      align-self: stretch;

      a {
        margin-right: 0;
      }

      &.show {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 224px;
        display: flex;
      }
    }

    .hamburger-menu {
      display: block;
    }
  }
}
