.main {
  display: flex;
  flex-direction: column;
  .one {
    padding-top: 82px;
    height: 834px;
    .image {
      height: 834px;
      background-image: url('../../../public/images/main_1.svg');
      filter: blur(25px);
      -webkit-filter: blur(25px);
    }
    .context {
      position: absolute;
      top: 35%;
      left: 38%;
      z-index: 2;
      // padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        color: #585858;
        text-align: center;
        font-family: Amaranth;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 98.567px; /* 164.278% */
        letter-spacing: 0.684px;
        margin-bottom: 8px;
      }
      span {
        color: var(--Black-T, #44423f);
        text-align: center;

        /* Subtitle 2 (R) */
        font-family: Roboto;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 165.217% */
      }
      .downloads-top {
        display: flex;
        margin-top: 30px;
        gap: 8px;

        .apple {
          &:hover {
            img {
              content: url('../../../public/images/apple_hover.svg');
            }
          }

          img {
            transition: transform 0.3s ease;
          }

          &:hover img {
            transform: scale(1.03);
          }
        }

        .google {
          &:hover {
            img {
              content: url('../../../public/images/google_hover.svg');
            }
          }

          img {
            transition: transform 0.3s ease;
          }

          &:hover img {
            transform: scale(1.03);
          }
        }
      }
    }
  }
  .two {
    background: #faf6f4;
    .ref-wrapper {
      z-index: 2;
      display: flex;
      height: 676px;
      padding: 40px 150px;
      justify-content: center;
      align-items: center;
      gap: 50px;
      flex-shrink: 0;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 346.667px;
      }

      h3 {
        color: var(--Black-T, #44423f);
        text-align: center;

        /* Subtitle 1 (B) */
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px; /* 135.714% */
        margin-top: 12px;
      }
      span {
        margin-top: 10px;
        color: var(--Black-T, #44423f);
        text-align: center;

        /* Body (R) */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        width: 363px;
      }
    }
  }
  .three {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1136px;
    .body {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 222px;
      .context {
        display: flex;
        flex-direction: column;
        width: 474px;
        h3 {
          color: var(--Orange-T, var(--Orange1, #ff8e3a));
          margin-bottom: 10px;

          /* Subtitle 1 (B) */
          font-family: Roboto;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px; /* 135.714% */
        }
        h2 {
          color: var(--Black-T, #44423f);

          /* Title 1 (B) */
          font-family: Roboto;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 66px; /* 132% */
        }
        span {
          color: var(--Black-T, #44423f);

          margin-top: 42px;
          font-family: Roboto;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px; /* 165.217% */
          width: 430px;
        }
      }
    }
  }
  .four {
    background: var(--Orange1, #ff8e3a);
    height: 1136px;

    .body {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 252px;
      margin-top: 239px;
      .context {
        display: flex;
        flex-direction: column;
        width: 474px;

        h2 {
          color: var(--White, #fff);
          font-family: Roboto;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 66px; /* 132% */
        }
        span {
          margin-top: 42px;
          color: var(--White, #fff);
          font-family: Roboto;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px; /* 165.217% */
          width: 474px;
        }
      }
    }
  }
  .five {
    .image {
      height: 2048px;
      background-image: url('../../../public/images/main_6_background.svg');
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .body {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        h3 {
          color: var(--Orange-T, var(--Orange1, #ff8e3a));

          /* Subtitle 1 (B) */
          font-family: Roboto;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px; /* 135.714% */
        }
        h2 {
          color: var(--Black-T, #44423f);

          /* Title 1 (B) */
          font-family: Roboto;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 66px; /* 132% */
          margin-bottom: 42px;
          margin-top: 6px;
        }
        span {
          color: var(--Black-T, #44423f);
          font-family: Roboto;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px; /* 165.217% */
          width: 430px;
        }
      }
      .top {
        gap: 208.42px;
        margin-bottom: 207px;
        .context {
          width: 486px;
        }
      }
      .bottom {
        gap: 226.58px;
        .context {
          width: 510px;
        }
      }
    }
  }
  .six {
    height: 1416px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .context {
      width: 574px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        color: var(--Orange-T, var(--Orange1, #ff8e3a));
        text-align: center;

        /* Subtitle 1 (B) */
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px; /* 135.714% */
      }
      h2 {
        color: var(--Black-T, #44423f);
        text-align: center;

        /* Title 1 (B) */
        font-family: Roboto;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 66px; /* 132% */
      }
      span {
        color: var(--Black-T, #44423f);
        text-align: center;

        /* Subtitle 2 (R) */
        font-family: Roboto;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 165.217% */
        margin-top: 42px;
        margin-bottom: 42px;
      }
      .more {
        border-radius: 100px;
        background: #3c3837;
        display: flex;
        height: 54px;
        padding: 4px 20px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: var(--light-white, #fdfdfd);
        text-align: center;
        width: 148px;

        /* Secondary (M) */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */

        margin-bottom: 80px;
      }
    }
  }
  .seven {
    background-image: url('../../../public/images/main_8_background.svg');
    .image {
      .ref-wrapper {
        height: 537px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        h2 {
          color: var(--Black-T, #44423f);
          text-align: center;

          /* Title 1 (B) */
          font-family: Roboto;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 66px; /* 132% */
        }
        .downloads-bottom {
          display: flex;
          gap: 8px;
          .apple {
            &:hover {
              img {
                content: url('../../../public/images/apple_hover.svg');
              }
            }

            img {
              transition: transform 0.3s ease;
            }

            &:hover img {
              transform: scale(1.03);
            }
          }

          .google {
            &:hover {
              img {
                content: url('../../../public/images/google_hover.svg');
              }
            }

            img {
              transition: transform 0.3s ease;
            }

            &:hover img {
              transform: scale(1.03);
            }
          }
        }
      }
    }
  }
  .top-button {
    position: fixed;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 999;

    &:hover {
      img {
        content: url('../../../public/images/top_button_hover.svg');
      }
    }

    img {
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}
@media (max-width: 768px) {
  .main {
    margin: 0;
    padding: 0;
    width: 100vw;

    .one {
      padding-top: 82px;
      width: 100vw;
      height: 634px;
      .image {
        height: 634px;
        width: 100vw;
        background-image: url('../../../public/images/main_1_m.svg');
        filter: blur(25px);
        -webkit-filter: blur(25px);
      }
      .context {
        position: absolute;
        top: 25%;
        left: 10%;
        z-index: 2;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          color: #585858;
          text-align: center;
          font-family: Amaranth;
          font-size: 42px;
          font-style: normal;
          font-weight: 400;
          line-height: 68.997px; /* 164.278% */
          letter-spacing: 0.479px;
        }
        span {
          color: var(--Black-T, #44423f);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          width: 350px;
        }
        .downloads-top {
          display: flex;
          margin-top: 30px;
          gap: 8px;

          .apple {
            &:hover {
              transform: none;
              content: none;
            }

            &:hover img {
              transform: none;
              content: none;
            }
          }

          .google {
            &:hover {
              transform: none;
              content: none;
            }

            &:hover img {
              transform: none;
              content: none;
            }
          }
        }
      }
    }
    .two {
      background: #faf6f4;
      .ref-wrapper {
        z-index: 2;
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 100px 100px;
        justify-content: center;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 363px;
          img {
            width: 140px;
            height: 140px;
          }
          h3 {
            color: var(--Black-T, #44423f);
            text-align: center;

            /* Subtitle 2 (B) */
            font-family: Roboto;
            font-size: 23px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 165.217% */
          }
          .ai-counselor {
            width: 292px;
          }

          span {
            color: var(--Black-T, #44423f);
            text-align: center;

            /* Secondary (R) */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            width: 350px;
          }
        }
      }
    }
    .three {
      width: 100vw;
      height: auto;
      padding: 100px 0px;

      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        .context {
          display: flex;
          flex-direction: column;
          width: 315px;
          height: 184px;
          h3 {
            color: var(--Orange-T, var(--Orange1, #ff8e3a));
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 150% */
          }
          h2 {
            color: var(--Black-T, #44423f);

            /* Subtitle 1 (B) */
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 135.714% */
          }
          span {
            color: var(--Black-T, #44423f);

            /* Secondary (R) */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            width: 315px;
            margin-top: 20px;
          }
        }
      }
    }
    .four {
      background: var(--Orange1, #ff8e3a);
      width: 100vw;
      height: auto;
      padding: 100px 0px;
      .body {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 40px;
        margin-top: 0px;
        .context {
          display: flex;
          flex-direction: column;
          width: 315px;

          h2 {
            color: var(--White, #fff);

            /* Subtitle 1 (B) */
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 135.714% */
          }
          span {
            color: var(--White, #fff);
            width: 315px;

            /* Secondary (R) */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-top: 20px;
          }
        }
      }
    }
    .five {
      width: 100vw;

      .image {
        height: auto;
        padding: 100px 0px 80px 0px;
        width: 100vw;
        background-image: url('../../../public/images/main_6_background_m.svg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .body {
          display: flex;
          justify-content: center;
          align-items: center;
          h3 {
            color: var(--Orange-T, var(--Orange1, #ff8e3a));
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 150% */
          }
          h2 {
            color: var(--Black-T, #44423f);

            /* Subtitle 1 (B) */
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 135.714% */
            margin-bottom: 20px;
          }
          span {
            color: var(--Black-T, #44423f);

            /* Secondary (R) */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
        .top {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin-bottom: 50px;
          .context {
            width: 315px;
          }
        }
        .bottom {
          gap: 40px;
          display: flex;
          flex-direction: column-reverse;
          margin-top: 50px;

          .context {
            width: 315px;
          }
        }
      }
    }
    .six {
      width: 100vw;
      height: auto;
      padding: 100px 0px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .context {
        width: 315px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          color: var(--Orange-T, var(--Orange1, #ff8e3a));
          text-align: center;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 150% */
        }
        h2 {
          color: var(--Black-T, #44423f);
          text-align: center;

          /* Subtitle 1 (B) */
          font-family: Roboto;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px; /* 135.714% */
        }
        span {
          color: var(--Black-T, #44423f);
          text-align: center;

          /* Secondary (R) */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
        .more {
          border-radius: 85.185px;
          background: #3c3837;
          display: flex;
          height: 46px;
          padding: 3.407px 17.037px;
          justify-content: center;
          align-items: center;
          gap: 1.704px;
          color: var(--light-white, #fdfdfd);
          text-align: center;
          width: 120px;

          /* Secondary (M) */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */

          margin-bottom: 80px;
        }
      }
    }
    .seven {
      width: 100vw;
      background-image: url('../../../public/images/main_8_background_m.svg');
      .ref-wrapper {
        .image {
          height: 400px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
          h2 {
            color: var(--Black-T, #44423f);
            text-align: center;

            /* Subtitle 1 (B) */
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 135.714% */
          }
          .downloads-bottom {
            display: flex;
            gap: 8px;
            .apple {
              &:hover {
                transform: none;
                content: none;
              }

              &:hover img {
                transform: none;
                content: none;
              }
            }

            .google {
              &:hover {
                transform: none;
                content: none;
              }

              &:hover img {
                transform: none;
                content: none;
              }
            }
          }
        }
      }
    }
  }
  .top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    // cursor: pointer;
    z-index: 999;

    &:hover {
      transform: none;
      content: none;
    }

    &:hover img {
      transform: none;
      content: none;
    }
  }
}
