.disclaimer {
  @media (max-width: 768px) {
    padding: 30px;

    h3 {
      font-size: large;
    }

    p {
      margin-bottom: 10px;
    }
  }
  margin: 0 auto;
  padding: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    text-align: center;
    color: #333;
    font-size: xx-large;
    font-weight: 800;
    margin-bottom: 40px;
  }

  strong {
    font-weight: bold;
  }

  p {
    line-height: 1.5;
    margin-bottom: 15px;

    &.emphasize {
      font-style: italic;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 10px;
  }
}
