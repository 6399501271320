.delete-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .delete-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    label {
      display: flex;
      flex-direction: column;
      width: auto;
      height: auto;
      input {
        width: 150px;
        height: 30px;
      }
    }
    button {
      width: 150px;
    }
  }
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 150px;
    font-style: italic;
  }
}

@media (max-width: 768px) {
  .delete-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .delete-form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      label {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        font-size: 25px;
        input {
          width: 200px;
          height: 20px;
        }
      }
      button {
        width: auto;
      }
    }
    p {
      margin-top: 50px;
      font-size: 20px;
      font-style: italic;

      width: 200px;
    }
  }
}
