.footer {
  height: 398px;
  background: #2d2c2b;
  padding: 50px 150px 100px 150px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .area {
    height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;
    img {
      width: 99.68px;
      height: 47.6px;
      flex-shrink: 0;
    }
    .contact {
      display: flex;
      gap: 30px;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 56px;
        a {
          color: var(--White, #fff);

          /* Smallest (R) */
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 56px;
        a {
          color: #c0c0c0;

          /* Smallest (R) */
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
        }
      }
    }
    .policy {
      display: flex;
      gap: 16px;

      a {
        color: var(--White, #fff);
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 153.846% */
        text-decoration-line: underline;
      }
    }
    .copyright {
      color: #c0c0c0;

      /* Smallest (R) */
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }
}
@media (max-width: 768px) {
  .footer {
    height: 398px;
    background: #2d2c2b;
    padding: 50px 30px 100px 30px;
    display: flex;
    flex-direction: column;
    .area {
      height: 248px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      flex: 1 0 0;
      img {
        width: 58.635px;
        height: 28px;
        flex-shrink: 0;
      }
      .contact {
        display: flex;
        gap: 30px;
        .title {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 92px;
          a {
            color: #fff;

            /* Smallest (R) */
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            margin-bottom: 6px;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 92px;
          a {
            color: #c0c0c0;

            /* Smallest (R) */
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            margin-bottom: 6px;
          }
        }
      }
      .policy {
        display: flex;
        gap: 16px;

        a {
          color: var(--White, #fff);
          font-family: Roboto;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 153.846% */
          text-decoration-line: underline;
        }
      }
      .copyright {
        color: #c0c0c0;

        /* Smallest (R) */
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
      }
    }
  }
}
